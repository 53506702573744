/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "fontsource-poppins"
import "fontsource-poppins/300.css"
import "fontsource-poppins/500.css"
import Container from "./Container"
import "./layout.css"

const Layout = ({ children }) => {
    return (
        <Container>
            {children}
        </Container>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
