import React from "react"
import styles from "./styles.module.css"
import Header from "../Header"
import Footer from "../Footer"

const Container = ({ children }) => (
    <div className={styles.wrapper}>
        <Header />
        <main className={styles.main}>
            {children}
        </main>
        <Footer />
    </div>
)

export default Container
