import { Link } from "gatsby"
import React, {useState} from "react"
import styles from "./styles.module.css"
import Button from "../Button"
import menu from "../../images/menu.svg"

const pages = [{
    url: "platform",
    title: "Platform"
}, {
    url: "who",
    title: "Who's it for"
}, {
    url: "pricing",
    title: "Pricing"
}, {
    url: "about",
    title: "About"
}, {
    url: "guide",
    title: "Guide"
}, {
    url: "faq",
    title: "FAQ"
}]

const Header = () => {
    const [open, toggleMenu] = useState(false)

    const handleOpen = () => {
        const body = document.querySelector('body')
        body.style.overflow = 'hidden'
        toggleMenu(true)
    }

    const handleClose = () => {
        const body = document.querySelector('body')
        body.style.overflow = ''
        toggleMenu(false)
    }

    return (
        <header className={styles.header}>
            <div className={styles.container}>
                <Link to="/"><div className={styles.logo}/></Link>
                <div className={styles.links}>
                    {pages.map(item =>
                        <Link key={item.url} to={`/${item.url}`} className={styles.link}>{item.title}</Link>
                    )}
                    <Button link="https://app.framelink.co/app" text="Sign in" external style={{marginLeft: '25px'}} />
                </div>
                <img src={menu} className={styles.icon} onClick={handleOpen} />
            </div>
            <div className={open ? styles.menuOpen : styles.menu}>
                <div className={open ? styles.backdropVisible : styles.backdrop} onClick={handleClose} />
                <div className={open ? styles.listVisible : styles.list}>
                    {pages.map(item =>
                        <Link key={item.url} to={`/${item.url}`} onClick={handleClose} className={styles.link}>
                            {item.title}
                        </Link>
                    )}
                    <Button link="https://app.framelink.co/app" text="Sign in" external style={{marginTop: '25px'}} />
                </div>
            </div>
        </header>
    )
}

export default Header
