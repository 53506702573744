import { Link } from "gatsby"
import React from "react"
import facebook from "../../images/facebook.svg"
import twitter from "../../images/twitter.svg"
import styles from "./styles.module.css"

const Footer = () => (
    <footer className={styles.main}>
        <div className={styles.container}>
            <div className={styles.block}>
                <Link to="/about" className={styles.link}> About us </Link>
                <Link to="/privacy" className={styles.link}> Privacy Policy </Link>
                <Link to="/terms" className={styles.link}> Terms & Conditions </Link>
                <Link to="/guide" className={styles.link}> FrameLink Guide </Link>
                <Link to="/faq" className={styles.link}> FAQ </Link>
                {/* <Link to="/blog" className={styles.link}> Blog </Link> */}
            </div>
            <div className={styles.block} style={{color: '#fff', fontSize: '16px'}}>
                © 2021 - FrameLink
            </div>
            <div className={styles.block}>
                <a href="https://www.facebook.com/framelink.co" target="_blank" className={styles.icon} rel="noreferrer">
                    <img src={facebook} />
                </a>
                <a href="https://twitter.com/FrameLink_co" target="_blank" className={styles.icon} rel="noreferrer">
                    <img src={twitter} />
                </a>
            </div>
        </div>
    </footer>
)

export default Footer
